import { decorate, observable, action } from 'mobx';
import firebase from '../firebase';

const dateFormat = require('dateformat');

class Auth {
  constructor() {
    this.authenticated = false;
    this.redirect = false;
    this.user = null;
    this.loading = true;
    this.loginErrorMessage = '';
    this.isError = false;
    this.items = [];
  }

  checkAuthenticated() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authenticated = true;
        this.user = user;
      } else {
        this.authenticated = false;
        this.loading = false;
        this.user = null;
      }
    });
  }


  signOut() {
    firebase.auth().signOut().then(() => {
      this.redirect = true;
      this.user = null;
      this.items = [];
    });
  }

  getAlerts() {
    firebase.auth().currentUser.getIdToken(true)
      .then(() => {
        const itemsRef = firebase.database().ref('items');
        itemsRef.on('value', (snapshot) => {
          const getitems = snapshot.val();
          if (getitems) {
            const newState = Object.keys(getitems).map(item => ({
              id: item,
              title: getitems[item].title,
              body: getitems[item].body,
              currentState: getitems[item].currentState,
              sentDate: dateFormat(getitems[item].sentDate, 'dd/mm/yy h.MM TT'),
              sentDateSort: getitems[item].sentDate,
              sentBy: getitems[item].sentBy,
              target: getitems[item].target,
              sound: getitems[item].sound,
              expiry: getitems[item].expiry,
              type: getitems[item].type,
            }));
            this.items = newState;
          }
        });
      }).catch((error) => {
        this.loginErrorMessage = error;
      });
  }

  addNotification(item) {
    firebase.auth().currentUser.getIdToken(true)
      .then(() => {
        const itemsRef = firebase.database().ref('items');
        itemsRef.push(item);
      }).catch((error) => {
        this.loginErrorMessage = error;
      });
  }

  login(email, password) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        this.loading = false;
        this.authenticated = true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        this.loading = false;
        this.isError = true;
        if (errorCode === 'auth/wrong-password') {
          this.loginErrorMessage = 'Wrong password.';
        } else {
          this.loginErrorMessage = errorMessage;
        }
      });
  }

  getUserEmail() {
    const user = firebase.auth().currentUser;
    return ((user != null) ? user.email : '');
  }
}

decorate(Auth, {
  authenticated: observable,
  user: observable,
  items: observable,
  loading: observable,
  isError: observable,
  loginErrorMessage: observable,
  checkAuthenticated: action,
  signOut: action,
  login: action,
  getAlerts: action,
  addNotification: action,
  getUserEmail: action,
});

export default new Auth();
