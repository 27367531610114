// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import {
  Col, Container, Button, Row, Form, FormGroup, Label, Input, CustomInput,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import DataTable from './DataTable';

const dateFormat = require('dateformat');

class Display extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      body: '',
      target: '',
      sound: 'Disabled',
      currentState: 'Pending',
      testMode: false,
      expiry: '',
      items: [],
      type: 'Emergency',
      reviewModal: false,
    };
    this.reviewPopup = this.reviewPopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
  }

  reviewPopup(e) {
    e.preventDefault();
    this.setState({
      reviewModal: !this.state.reviewModal,
    });
  }

  sendNotification(e) {
    e.preventDefault();
    const item = {
      title: this.state.title,
      body: this.state.body,
      target: this.state.target,
      sentBy: this.props.auth.user.email,
      currentState: this.state.currentState,
      sentDate: dateFormat((new Date()).toISOString()),
      expiry: this.state.expiry,
      type: this.state.type,
    };
    this.props.auth.addNotification(item);
    this.setState({
      title: '',
      body: '',
      target: '',
      sound: 'Disabled',
      testMode: false,
      expiry: 0,
      type: 'Emergency',
      reviewModal: false,
    });
  }

  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
    if (target.type === 'checkbox' && target.checked) {
      this.setState({
        title: 'TEST ALERT',
        expiry: 1,
        type: 'Test',
      });
    } else if (target.type === 'checkbox' && !target.checked) {
      this.setState({
        title: '',
        expiry: 0,
        type: 'Emergency',
      });
    }
  }

  componentDidMount() {
    this.props.auth.getAlerts();
    this.props.auth.loading = false;
  }

  createSelectItems() {
    const items = [];
    items.push(<option key={0} value=''></option>);
    for (let i = 1; i <= 12; i++) {
      items.push(<option key={i} value={i}>{`${i} Hour`}</option>);
    }
    return items;
  }

  render() {
    return (
            <Container>
                <Row style={{ margin: '20px 0px 0px 0px' }}>
                <Col xs="12" sm="12" md ="12" lg="5">
                    <Form onSubmit={this.reviewPopup} className="add-item">
                    <div className="text-center" style={{ padding: '.5rem' }}>
                        <h4>Send an Alert</h4>
                    </div>
                        <FormGroup row>
                            <Col sm={10}>
                                <CustomInput type="checkbox" checked = {this.state.testMode}
                                  name="testMode" id="ntestalert"
                                  label="Send a test alert"
                                  onChange={this.handleChange} value={this.state.testMode} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="ntarget" sm={4}>Campus</Label>
                            <Col sm={7}>
                            <Input type="select" name="target" id="ntarget" required
                              onChange={this.handleChange} value={this.state.target}>
                                <option value=""></option>
                                <option value="Auckland">Auckland</option>
                                <option value="Manawatu">Manawatu</option>
                                <option value="Wellington">Wellington</option>
                                <option value="All">All campuses</option>
                            </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="ntitle" sm={4}>Alert Title</Label>
                            <Col sm={7}>
                            <Input type="text" name="title" required readOnly ={this.state.testMode}
                              onChange={this.handleChange} value={this.state.title} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="nbody" sm={4}>Alert Text</Label>
                            <Col sm={8}>
                            <Input
                              type="textarea"
                              name="body"
                              required
                              onChange={this.handleChange}
                              value={this.state.body} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="nexpiry" sm={4}>Alert expiry</Label>
                            <Col sm={7}>
                            <Input type="select" name="expiry" id="nexpiry"
                              disabled = {this.state.testMode}
                              onChange={this.handleChange} value={this.state.expiry}
                              required
                              readOnly ={this.state.testMode}>
                              {this.createSelectItems()}
                            </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup check row>
                            <Button className="btn-dark-massey">Review</Button>
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs="12" sm="12" md="12" lg="7">
                    <DataTable />
                </Col>
                </Row>
                 <Modal isOpen={this.state.reviewModal} toggle={this.reviewPopup}
                  className={this.props.className}>
                  <ModalHeader toggle={this.reviewPopup}>Review alert</ModalHeader>
                  <ModalBody>
                    <h6 className="font-weight-bold">Campus</h6>
                    <h6 className="font-weight-light">{this.state.target}</h6>
                    <br/>
                    <h6 className="font-weight-bold">Title</h6>
                    <h6 className="font-weight-light">{this.state.title}</h6>
                    <br/>
                    <h6 className="font-weight-bold">Text</h6>
                    <h6 className="font-weight-light">{this.state.body}</h6>
                    <br/>
                    <h6 className="font-weight-bold">Expiry</h6>
                    <h6 className="font-weight-light">{this.state.expiry}</h6>
                    <br/>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.reviewPopup}>Cancel</Button>
                    <Button className="btn-dark-massey"
                      onClick={this.sendNotification}>Send alert</Button>
                  </ModalFooter>
                </Modal>
              </Container>
    );
  }
}

export default inject('auth')(observer(Display));
