// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

class Logout extends Component {
  componentWillMount() {
    this.props.auth.signOut();
    this.props.auth.authenticated = false;
    this.props.auth.loading = false;
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default inject('auth')(observer(Logout));
