// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  Container,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  logout() {
    this.props.auth.loading = true;
    this.props.history.push('/logout');
  }


  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
          <Container>
            <Navbar className="bg-massey" dark expand="md">
              <NavbarBrand
                className="text-white font-weight-bold mx-auto"
                  href="/">Massey Emergency Alerts</NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    {this.props.authenticated
                      ? <NavItem>
                            <span className="text-white px-2">
                              {this.props.auth.getUserEmail()}</span>
                            <Button
                              className="btn-light-massey" onClick={(this.logout)}>Logout</Button>
                        </NavItem>
                      : null
                    }
                </Nav>
              </Collapse>
            </Navbar>
          </Container>
    );
  }
}

export default withRouter(inject('auth')(observer(Header)));
