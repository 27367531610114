// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { observer, inject } from 'mobx-react';

class DataTable extends Component {
  isExpandableRow() {
    return true;
  }

  convertIntToDate(row) {
    return new Date(row.sentDate);
  }

  expandComponent(row) {
    return (
        <ul>
          <li style={ { whiteSpace: 'normal' } }>Title: {row.title}</li>
          <li style={ { whiteSpace: 'normal' } }>Text: {row.body}</li>
          <li>Campus: {row.target}</li>
          <li>Expiry: {row.expiry} hours</li>
          <li>Date Sent: {row.sentDate}</li>
          <li>Sent By: {row.sentBy}</li>
        </ul>
    );
  }

  dateSort(a, b, order) {
    if (order === 'desc') {
      return new Date(b.sentDateSort) - new Date(a.sentDateSort);
    }
    return new Date(a.sentDateSort) - new Date(b.sentDateSort);
  }

  createCustomButtonGroup() {
    return (
    <div style={ { textAlign: 'center', paddingTop: '5px' } }>
      <h4>Alert History</h4>
    </div>
    );
  }

  render() {
    const options = {
      expandRowBgColor: 'rgb(255, 255, 255)',
      defaultSortName: 'sentDateSort',
      defaultSortOrder: 'desc',
      btnGroup: this.createCustomButtonGroup,
    };
    return (
        <BootstrapTable
          data={ this.props.auth.items }
          pagination={true}
          options={ options }
          striped
          smartDisplay
          showHeader
          expandableRow={ this.isExpandableRow }
          expandComponent={ this.expandComponent }
          search>
          <TableHeaderColumn isKey hidden dataField='id'>id</TableHeaderColumn>
          <TableHeaderColumn dataField='type'>Alert Type</TableHeaderColumn>
          <TableHeaderColumn dataField='title'>Alert Title</TableHeaderColumn>
          <TableHeaderColumn dataField='currentState'>State</TableHeaderColumn>
          <TableHeaderColumn dataField='sentDate'>Date sent</TableHeaderColumn>
          <TableHeaderColumn hidden dataSort={ true }
            sortFunc = {this.dateSort} dataField='sentDateSort'>Date sent</TableHeaderColumn>
          <TableHeaderColumn hidden dataField='target'>Campus</TableHeaderColumn>
          <TableHeaderColumn hidden dataField='body'>Alert Text</TableHeaderColumn>
          <TableHeaderColumn hidden dataField='sentBy'>Sent By</TableHeaderColumn>
        </BootstrapTable>
    );
  }
}

export default inject('auth')(observer(DataTable));
