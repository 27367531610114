// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { Container } from 'reactstrap';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { year: new Date().getFullYear() };
  }

  render() {
    return (
            <Container>
            <footer>
                {process.env.REACT_APP_RELEASE_VERSION}
            </footer>
            </Container>
    );
  }
}

export default Footer;
