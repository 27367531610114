// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import './App.css';
import { observer, inject } from 'mobx-react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Display from './components/Display';
import Logout from './components/Logout';
import LinearIndeterminate from './components/LinearIndeterminate';

function AuthenticatedRoute({ component: SetComponent, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (authenticated === true
        ? <SetComponent {...props}{...rest} />
        : <Redirect to='/login' />)
      } />
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref; }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 60 * 15} />
        {
          <BrowserRouter>
          <div className='main-content'>
            {this.props.auth.loading
              ? <LinearIndeterminate />
              : null
            }
            <Header authenticated={this.props.auth.authenticated}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/logout" component={Logout}/>
            <AuthenticatedRoute path="/"
              authenticated={this.props.auth.authenticated} component={Display} />
            <Footer />
          </div>
        </BrowserRouter>
        }
      </div>
    );
  }

  onIdle() {
    this.props.auth.signOut();
  }
}

export default inject('auth')(observer(App));
