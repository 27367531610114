// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import {
  Container, Col, Form,
  FormGroup, Label, Input,
  Button, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      validate: {
        emailState: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.authWithEmailPassword = this.authWithEmailPassword.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.props.auth.isError = !this.props.auth.isError;
  }

  authWithEmailPassword(event) {
    event.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    this.props.auth.loading = true;
    const interval = setInterval(this.updateLoadingCount, 5000);
    this.props.auth.login(email, password);
    clearInterval(interval);
  }


  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success';
    } else {
      validate.emailState = 'has-danger';
    }
    this.setState({ validate });
  }

  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  componentWillMount() {
    this.props.auth.checkAuthenticated();
  }


  render() {
    const { email, password } = this.state;
    if (this.props.auth.authenticated === true) {
      return <Redirect exact to='/' />;
    }

    return (
      <Container className="login">
        <h4>Log In</h4>
        <Form className="form" onSubmit={ event => this.authWithEmailPassword(event) }>
          <Col>
            <FormGroup>
              <Label>Username</Label>
              <Input
                type="email"
                name="email"
                id="exampleEmail"
                placeholder="Email address"
                disabled={this.props.auth.loading}
                value={ email }
                valid={ this.state.validate.emailState === 'has-success' }
                invalid={ this.state.validate.emailState === 'has-danger' }
                onChange={ (e) => {
                  this.validateEmail(e);
                  this.handleChange(e);
                } }
              />
              <FormFeedback valid></FormFeedback>
              <FormFeedback>Please input a correct email.</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input
                type="password"
                name="password"
                id="examplePassword"
                placeholder="Password"
                disabled={this.props.auth.loading}
                value={ password }
                onChange={ event => this.handleChange(event) }
            />
            </FormGroup>
          </Col>
          <Button disabled={this.props.auth.loading}>Submit</Button>
      </Form>
      <Modal isOpen={this.props.auth.isError} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Login Error</ModalHeader>
          <ModalBody>
           You have entered an invalid username or password.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default inject('auth')(observer(Login));
